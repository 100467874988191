import { DataloadState, FluidType, TimeStep } from 'enums';
import { DateTime } from 'luxon';
import {
  AggregatedEnedisMonthlyDataloads,
  Datachart,
  Dataload,
  DataloadEntity,
  DataloadValueDetail,
  EnedisMonthlyAnalysisData,
  FluidStatus,
  PerformanceIndicator,
  TimePeriod,
} from 'models';
import ConsumptionFormatterService from 'services/consumptionFormatter.service';
import ConsumptionValidatorService from 'services/consumptionValidator.service';
import ConverterService from 'services/converter.service';
import StellioAxiosClient from 'services/appAxios.service';
import {
  StellioEntityType,
  StellioGraphAggregated,
  StellioTemporalFluidMonitoring,
} from 'models/stellio.model';
import { HttpStatusCode } from 'axios';
import { getAlwaysArray } from 'utils/ecoGagnant';
import { appLogger } from 'utils/appLogger';

interface ISingleFluidChartData {
  chartData: Datachart | null;
  chartFluid: FluidType;
}

export default class ConsumptionDataManager {
  private readonly _client: StellioAxiosClient;
  private readonly _userSub: string;
  private readonly _consumptionFormatterService: ConsumptionFormatterService;
  // private readonly _queryRunnerService: QueryRunnerService
  private readonly _consumptionValidatorService: ConsumptionValidatorService;
  private readonly DURATION_MATCHING_CONFIG = {
    [TimeStep.HALF_AN_HOUR]: 'PT30M',
    [TimeStep.DAY]: 'P1D',
    [TimeStep.WEEK]: 'P1D',
    [TimeStep.MONTH]: 'P1M',
    [TimeStep.YEAR]: 'P1Y',
  };
  private readonly FLUID_TYPE_MATCHING_CONFIG = {
    [FluidType.ELECTRICITY]: 'Electricity',
    [FluidType.WATER]: 'Water',
    [FluidType.GAS]: 'Gas',
    [FluidType.MULTIFLUID]: 'MultiFluid',
  };

  constructor(client: StellioAxiosClient, userSub: string) {
    this._client = client;
    this._userSub = userSub;
    this._consumptionFormatterService = new ConsumptionFormatterService();
    // this._queryRunnerService = new QueryRunnerService(this._client)
    this._consumptionValidatorService = new ConsumptionValidatorService();
  }

  /**
   * Get graph data according on timeStep and fluidType
   * @param timePeriod TimePeriod
   * @param timeStep TimeStep
   * @param fluidTypes FluidType[]
   * @param fluidStatus FluidStatus[]
   * @param compareTimePeriod - Optional TimePeriod
   * @param isHome - Optional boolean
   * @param isExport - Optional boolean
   * @returns DataChart | null
   */
  public async getGraphData(
    timePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidTypes: FluidType[],
    fluidStatus?: FluidStatus[],
    compareTimePeriod?: TimePeriod,
    isHome?: boolean,
    isExport?: boolean
  ): Promise<Datachart | null> {
    const InputisValid: boolean =
      this._consumptionValidatorService.ValidateGetGraphData(
        timePeriod,
        timeStep,
        fluidTypes,
        compareTimePeriod,
        isExport
      );

    if (!InputisValid) {
      console.warn('Graph data validation => Input invalid');
      return null;
    }

    if (fluidTypes.length === 1 && !isHome) {
      const fluidType: FluidType = fluidTypes[0];
      // running the query
      const fetchedData: Datachart | null =
        await this.fetchSingleFluidGraphData(
          timePeriod,
          timeStep,
          fluidType,
          compareTimePeriod
        );

      // formatting data
      const formattedData: Datachart | null = this.formatGraphDataManager(
        fetchedData,
        timeStep,
        timePeriod,
        compareTimePeriod || null,
        fluidType,
        fluidStatus ? fluidStatus[fluidType] : undefined
      );

      return formattedData;
    } else if (fluidTypes.length > 1 || isHome) {
      const toBeAggregatedData: ISingleFluidChartData[] = [];
      for (const fluidType of fluidTypes) {
        const fetchedData = await this.fetchSingleFluidGraphData(
          timePeriod,
          timeStep,
          fluidType,
          compareTimePeriod
        );
        // formatting data
        const formattedData = this.formatGraphDataManager(
          fetchedData,
          timeStep,
          timePeriod,
          compareTimePeriod || null,
          fluidType,
          fluidStatus ? fluidStatus[fluidType] : undefined
        );
        // validating output data
        toBeAggregatedData.push({
          chartData: formattedData,
          chartFluid: fluidType,
        });
      }
      const aggregatedData: Datachart | null =
        this.aggregateGraphData(toBeAggregatedData);

      return aggregatedData;
    } else return null;
  }

  public async getMaxLoad(
    maxTimePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidTypes: FluidType[],
    compareMaxTimePeriod?: TimePeriod,
    isHome?: boolean,
    withDate?: boolean
  ): Promise<number | null | Dataload> {
    let allData;
    allData = await this.getGraphData(
      maxTimePeriod,
      timeStep,
      fluidTypes,
      undefined,
      compareMaxTimePeriod,
      isHome
    );
    return allData?.actualData
      ? Math.max(...allData.actualData.map((d) => d.value))
      : 0;
    // if (isHome) {
    //   allData = await this.getGraphData(
    //     maxTimePeriod,
    //     timeStep,
    //     fluidTypes,
    //     undefined,
    //     compareMaxTimePeriod,
    //     isHome
    //   );
    //   return allData?.actualData
    //     ? Math.max(...allData.actualData.map((d) => d.value))
    //     : 0;
    // } else {
    // const max = await this._queryRunnerService.fetchFluidMaxData(
    //   maxTimePeriod,
    //   timeStep,
    //   fluidTypes[0],
    //   withDate
    // )
    // return max
    // }
  }

  // fetch last dataload available for a given fluid - return the daily data
  public async getLastDataload(
    fluidTypes: FluidType
  ): Promise<Dataload[] | null> {
    const timePeriod = {
      startDate: DateTime.now().plus({ days: -3 }).startOf('day'),
      endDate: DateTime.now(),
    };

    // const data = await this._queryRunnerService.fetchFluidData(
    //   timePeriod,
    //   TimeStep.DAY,
    //   fluidTypes
    // )
    // return data
    return null;
  }

  public async getPerformanceIndicators(
    timePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidTypes: FluidType[],
    compareTimePeriod?: TimePeriod
  ): Promise<PerformanceIndicator[]> {
    const performanceIndicators: PerformanceIndicator[] = [];
    for (const fluidType of fluidTypes) {
      const graphData: Datachart | null = await this.getGraphData(
        timePeriod,
        timeStep,
        [fluidType],
        undefined,
        compareTimePeriod
      );

      if (graphData) {
        const performanceIndicator: PerformanceIndicator = {
          value: null,
          compareValue: null,
          percentageVariation: null,
          price: null,
        };

        const actualDataIsValid = this.PerformanceIndicatorsDataIsValid(
          graphData.actualData
        );

        if (actualDataIsValid) {
          performanceIndicator.value = this.calculatePerformanceIndicatorValue(
            graphData.actualData
          );
          if (graphData.actualData[0]?.price) {
            performanceIndicator.price =
              this.calculatePerformanceIndicatorPrice(graphData.actualData);
          }
        }

        if (
          actualDataIsValid &&
          graphData.comparisonData &&
          this.PerformanceIndicatorsDataIsValid(graphData.comparisonData)
        ) {
          const comparisonSumValue = this.calculatePerformanceIndicatorValue(
            graphData.comparisonData
          );
          performanceIndicator.compareValue = comparisonSumValue;
          performanceIndicator.percentageVariation =
            this.calculatePerformanceIndicatorVariationPercentage(
              performanceIndicator.value || 0,
              comparisonSumValue
            );
        }

        performanceIndicators[fluidType] = performanceIndicator;
      }
    }

    return performanceIndicators;
  }

  private PerformanceIndicatorsDataIsValid(data: Dataload[]): boolean {
    if (!data) return false;

    const missingValue = data.find((element) => element.value === -1);
    if (missingValue) return false;

    return true;
  }

  public calculatePerformanceIndicatorValue(data: Dataload[]): number {
    return data.reduce((a, b) => (b.value !== -1 ? a + b.value : a), 0);
  }

  public calculatePerformanceIndicatorPrice(data: Dataload[]): number {
    return data.reduce((a, b) => (b.price ? a + b.price : a), 0);
  }

  private calculatePerformanceIndicatorVariationPercentage(
    dataSum: number,
    comparisonDataSum: number
  ): number | null {
    return comparisonDataSum !== 0 ? dataSum / comparisonDataSum - 1 : null;
  }

  public async fetchSingleFluidGraphData(
    timePeriod: TimePeriod,
    timeStep: TimeStep,
    fluidType: FluidType,
    compareTimePeriod?: TimePeriod
  ): Promise<Datachart | null> {
    let actualData: Dataload[] | null = [];
    let comparisonData: Dataload[] | null = [];
    let singleFluidGraphData: Datachart | null = null;

    const transformToEcolyoModel = (
      temporalData: StellioGraphAggregated
    ): Dataload => {
      let dataloadState = DataloadState.UPCOMING;
      if (temporalData[0]) dataloadState = DataloadState.VALID;
      const isToday = DateTime.fromISO(temporalData[1]).hasSame(
        DateTime.local(),
        'day'
      );
      if (isToday) dataloadState = DataloadState.COMING;

      const dateIndex = 1;
      const dateTime = DateTime.fromISO(temporalData[dateIndex]).toLocal(); // Handles UTC+1 or UTC+2

      return {
        date: dateTime,
        value: temporalData[0],
        state: dataloadState,
        price: temporalData[3],
        valueDetail: null,
      };
    };

    if (compareTimePeriod) {
      const result = await Promise.all([
        this.getStellioTemporalData(timePeriod, timeStep, fluidType),
        this.getStellioTemporalData(compareTimePeriod, timeStep, fluidType),
      ]);

      actualData = result[0]?.map(transformToEcolyoModel) ?? null;
      comparisonData = result[1]?.map(transformToEcolyoModel) ?? null;
    } else {
      const result = await this.getStellioTemporalData(
        timePeriod,
        timeStep,
        fluidType
      );
      actualData = result?.map(transformToEcolyoModel) ?? null;
    }

    if (actualData) {
      singleFluidGraphData = {
        actualData: actualData,
        comparisonData: comparisonData,
      };
    }
    return singleFluidGraphData;
  }

  private formatGraphDataManager(
    data: Datachart | null,
    timeStep: TimeStep,
    timePeriod: TimePeriod,
    compareTimePeriod: TimePeriod | null,
    fluidType: FluidType,
    fluidStatus?: FluidStatus
  ): Datachart | null {
    if (!data) return null;

    const formattedActualData: Dataload[] =
      this._consumptionFormatterService.formatGraphData(
        data.actualData,
        timePeriod,
        timeStep,
        fluidType,
        fluidStatus
      );

    let formattedComparisonData: Dataload[] | null = null;
    if (compareTimePeriod)
      formattedComparisonData =
        this._consumptionFormatterService.formatGraphData(
          data.comparisonData ? data.comparisonData : [],
          compareTimePeriod,
          timeStep,
          fluidType,
          fluidStatus
        );

    const result: Datachart = {
      actualData: formattedActualData,
      comparisonData: formattedComparisonData,
    };

    return result;
  }

  /**
   * Check that fluidTypes contains data over a timestep.
   * @returns an array of FluidType that contains data
   */
  public async getFluidsWithData(
    fluidTypes: FluidType[],
    timeStep: TimeStep
  ): Promise<FluidType[]> {
    const fluidsWithData: FluidType[] = [];
    for (const fluidType of fluidTypes) {
      if (await this.checkDoctypeEntries(fluidType, timeStep)) {
        fluidsWithData.push(fluidType);
      }
    }
    return fluidsWithData;
  }

  public async getFluidsWithDataForTimePeriod(
    fluidTypes: FluidType[],
    timePeriod: TimePeriod,
    timeStep = TimeStep.MONTH
  ): Promise<FluidType[]> {
    const fluidsWithData: FluidType[] = [];
    for (const fluidType of fluidTypes) {
      const result = await this.getStellioTemporalData(
        timePeriod,
        timeStep,
        fluidType
      );

      // const data = await this._queryRunnerService.fetchFluidData(
      //   timePeriod,
      //   timeStep,
      //   fluidType
      // )

      if (result?.length) {
        fluidsWithData.push(fluidType);
      }
    }

    return fluidsWithData;
  }

  /**
   * Retrieves an array of fluid types that have incomplete data for a given month.
   * 
   * Data is incomplete if at least one daily data is missing for the month.
   * 
   * @param {FluidType[]} fluidTypes
   * @param {DateTime} month - The month for which to check the data completeness.
   * @returns {Promise<FluidType[]>} 

   */
  public async getFluidsWithIncompleteData(
    fluidTypes: FluidType[],
    month: DateTime
  ): Promise<FluidType[]> {
    const fluidsWithIncompleteData: FluidType[] = [];
    const timePeriod: TimePeriod = {
      startDate: month.startOf('month'),
      endDate: month.endOf('month'),
    };
    for (const fluidType of fluidTypes) {
      const result = await this.getStellioTemporalData(
        timePeriod,
        TimeStep.DAY,
        fluidType
      );

      const monthDaysInMonth = month.daysInMonth ?? 30;

      if (result?.length && result?.length < monthDaysInMonth) {
        fluidsWithIncompleteData.push(fluidType);
      }

      // const data = await this._queryRunnerService.fetchFluidData(
      //   timePeriod,
      //   TimeStep.DAY,
      //   fluidType
      // )
      // if (data?.length && data?.length < month.daysInMonth) {
      //   fluidsWithIncompleteData.push(fluidType)
      // }
    }
    return fluidsWithIncompleteData;
  }

  public async fetchAllFirstDateData(
    fluidTypes: FluidType[],
    timeStep?: TimeStep
  ): Promise<(DateTime | null)[]> {
    let firstDay = null;
    const firstDays = [];
    // for (const fluidType of fluidTypes) {
    //   firstDay =
    //     (await this._queryRunnerService.getFirstDateData(
    //       fluidType,
    //       timeStep
    //     )) || null
    //   firstDays.push(firstDay)
    // }
    return firstDays;
  }

  public async fetchAllLastDateData(
    fluidTypes: FluidType[],
    timeStep?: TimeStep
  ): Promise<(DateTime | null)[]> {
    let lastDay = null;
    const lastDays = [];
    if (fluidTypes.length === 1) {
      // lastDay =
      //   (await this._queryRunnerService.getLastDateData(
      //     fluidTypes[0],
      //     timeStep
      //   )) || null
      // lastDays.push(lastDay)
    } else if (fluidTypes.length > 1) {
      for (const fluidType of fluidTypes) {
        // lastDay =
        //   (await this._queryRunnerService.getLastDateData(
        //     fluidType,
        //     timeStep
        //   )) || null
        // lastDays.push(lastDay)
      }
    }
    return lastDays;
  }

  public async checkDoctypeEntries(
    fluideType: FluidType,
    timeStep: TimeStep
  ): Promise<boolean> {
    // const queryResult = await this._queryRunnerService.getEntries(
    //   fluideType,
    //   timeStep
    // )
    // if (queryResult?.data.length > 0) {
    //   return true
    // }
    return false;
  }

  private aggregateGraphData(
    singleFluidCharts: ISingleFluidChartData[]
    // ,withComparison: boolean = true
  ): Datachart | null {
    if (singleFluidCharts[0]?.chartData) {
      const converterService = new ConverterService();
      const resultChartData: Datachart = {
        actualData: [],
        comparisonData: [],
      };

      let length = 0;
      if (singleFluidCharts[0].chartData.comparisonData) {
        length = Math.max(
          singleFluidCharts[0].chartData.comparisonData.length,
          singleFluidCharts[0].chartData.actualData.length
        );
      } else length = singleFluidCharts[0].chartData.actualData.length;

      for (let i = 0; i < length; i++) {
        let aggregatedConvertedValue = 0;
        let comparisonAggregatedConvertedValue = 0;

        const tempAggregatedState: DataloadState[] = [];
        const tempComparisonAggregatedState: DataloadState[] = [];

        let noDataCount = 0;
        let comparisonNoDataCount = 0;

        const convertedValueDetail: DataloadValueDetail[] = [];
        const comparisonConvertedValueDetail: DataloadValueDetail[] = [];

        for (const singleFluidChart of singleFluidCharts) {
          if (!singleFluidChart.chartData) break;
          if (!singleFluidChart.chartData.actualData[i]) break;
          const value = singleFluidChart.chartData.actualData[i].value;
          tempAggregatedState.push(
            singleFluidChart.chartData.actualData[i].state
          );

          let convertedValue = -1;
          if (value === -1) noDataCount++;
          else {
            convertedValue = converterService.LoadToEuro(
              value,
              singleFluidChart.chartFluid,
              singleFluidChart.chartData.actualData[i].price
            );
            aggregatedConvertedValue += convertedValue;
          }

          convertedValueDetail[singleFluidChart.chartFluid] = {
            value: convertedValue,
            state: singleFluidChart.chartData.actualData[i].state,
          };

          if (singleFluidChart.chartData.comparisonData?.[i]) {
            const comparisonValue =
              singleFluidChart.chartData.comparisonData[i].value;
            tempComparisonAggregatedState.push(
              singleFluidChart.chartData.comparisonData[i].state
            );

            let convertedComparisonValue = -1;
            if (comparisonValue === -1) comparisonNoDataCount++;
            else {
              convertedComparisonValue = converterService.LoadToEuro(
                comparisonValue,
                singleFluidChart.chartFluid,
                singleFluidChart.chartData.comparisonData[i].price
              );
              comparisonAggregatedConvertedValue += convertedComparisonValue;
            }

            comparisonConvertedValueDetail[singleFluidChart.chartFluid] = {
              value: convertedComparisonValue,
              state: singleFluidChart.chartData.comparisonData[i].state,
            };
          }
        }

        if (singleFluidCharts.length === noDataCount)
          aggregatedConvertedValue = -1;
        if (singleFluidCharts.length === comparisonNoDataCount)
          comparisonAggregatedConvertedValue = -1;

        if (singleFluidCharts[0].chartData.actualData[i]) {
          // Define the aggregated state
          const aggregatedDataloadState: DataloadState =
            this._consumptionFormatterService.defineAggregatedDataloadState(
              tempAggregatedState
            );
          const actualDataLoad: Dataload = {
            date: singleFluidCharts[0].chartData.actualData[i].date,
            value: aggregatedConvertedValue,
            state: aggregatedDataloadState,
            valueDetail:
              aggregatedConvertedValue === -1 ? null : convertedValueDetail,
          };
          resultChartData.actualData.push(actualDataLoad);
        }

        if (
          singleFluidCharts[0].chartData.comparisonData &&
          resultChartData.comparisonData &&
          singleFluidCharts[0].chartData.comparisonData[i]
        ) {
          // Define the aggregated state
          const aggregatedComparisonDataloadState: DataloadState =
            this._consumptionFormatterService.defineAggregatedDataloadState(
              tempComparisonAggregatedState
            );
          const comparisonDataLoad: Dataload = {
            date: singleFluidCharts[0].chartData.comparisonData[i].date,
            value: comparisonAggregatedConvertedValue,
            state: aggregatedComparisonDataloadState,
            valueDetail:
              comparisonAggregatedConvertedValue === -1
                ? null
                : comparisonConvertedValueDetail,
          };
          resultChartData.comparisonData.push(comparisonDataLoad);
        }
      }
      return resultChartData;
    }

    return null;
  }

  /**
   * Check if user has activated load curve
   * By verifying 30 minutes data intervals are retrieved
   */
  public async checkHaveLoadCurveActivated(): Promise<boolean> {
    const oneDayTwoDaysAgo: TimePeriod = {
      startDate: DateTime.now().minus({ days: 2 }).startOf('day'),
      endDate: DateTime.now().minus({ days: 2 }).endOf('day'),
    };
    // const temporalData = await this.getStellioTemporalData(oneDayTwoDaysAgo, TimeStep.HALF_AN_HOUR, FluidType.ELECTRICITY);
    // TODO waiting for inputs
    return true;
  }

  private getCorrectedStartAndEndDate(
    timePeriod: TimePeriod,
    timeStep: TimeStep | string
  ) {
    let durationISO = this.DURATION_MATCHING_CONFIG[timeStep];
    let startDateIncluded = timePeriod.startDate.setZone('utc', {
      keepLocalTime: true,
    });
    let endDateIncluded = timePeriod.endDate.setZone('utc', {
      keepLocalTime: true,
    });

    switch (durationISO) {
      case 'PT30M':
      case 'P1D':
        endDateIncluded = endDateIncluded.plus({ days: 1 });
        break;
      case 'P1M':
        endDateIncluded = endDateIncluded.plus({ second: 0.001 });
        break;
      case 'P1Y':
        endDateIncluded = endDateIncluded.plus({ year: 1 });
        break;
    }

    const utcOffsetStartDate = timePeriod.startDate.toLocal().offset;
    const utcOffsetEndDate = timePeriod.endDate.toLocal().offset; // Get the offset in minutes

    const correctedStartDate = startDateIncluded
      .minus({
        minutes: utcOffsetStartDate,
      })
      .toISO({ includeOffset: false });

    const correctedEndDate = endDateIncluded
      .minus({ minutes: utcOffsetEndDate })
      .toISO({ includeOffset: false });

    return {
      startDateIncluded: `${correctedStartDate}Z`,
      endDateIncluded: `${correctedEndDate}Z`,
    };
  }

  public async getStellioTemporalData(
    timePeriod: TimePeriod,
    timeStep: TimeStep | string,
    fluidType: FluidType
  ): Promise<StellioGraphAggregated[] | null> {
    let durationISO = this.DURATION_MATCHING_CONFIG[timeStep];
    if (typeof timeStep === 'string') durationISO = timeStep;

    const fluidTypeName = this.FLUID_TYPE_MATCHING_CONFIG[fluidType];

    const { startDateIncluded, endDateIncluded } =
      this.getCorrectedStartAndEndDate(timePeriod, timeStep);

    const response = await this._client.getTemporalEntity<
      StellioTemporalFluidMonitoring[]
    >({
      entityType: StellioEntityType.FLUID_MONITORING,
      attrs: 'consumption,estimatedPrice',
      timerel: 'between',
      timeAt: startDateIncluded,
      endTimeAt: endDateIncluded,
      appendParams: `&options=aggregatedValues&aggrPeriodDuration=${durationISO}&aggrMethods=sum&q=fluidType=="${fluidTypeName}"`,
    });

    if (response.status !== HttpStatusCode.Ok) return null;

    const userFluidMonitoring = response.data.find((entity) =>
      entity.id.includes(this._userSub)
    );

    if (!userFluidMonitoring) return null;

    const consumptionTotalDataset = getAlwaysArray(
      userFluidMonitoring.consumption
    ).find((dataset) => dataset.datasetId.includes('Total'));
    const estimatedPriceTotalDataset = getAlwaysArray(
      userFluidMonitoring.estimatedPrice
    ).find((dataset) => dataset.datasetId.includes('Total'));

    if (!consumptionTotalDataset || !estimatedPriceTotalDataset) return null;
    if (
      consumptionTotalDataset.sum.length !==
      estimatedPriceTotalDataset.sum.length
    ) {
      console.warn(
        'Consumption and Estimated Price have different lengths - Misalignments of prices and consumptions are to be expected'
      );
    }

    const graphAggregatedPayload: StellioGraphAggregated[] =
      consumptionTotalDataset.sum.map((consumptionItem, i) => {
        const relativeEtimatedPrice = estimatedPriceTotalDataset.sum[i][0];
        return [...consumptionItem, relativeEtimatedPrice];
      });

    return graphAggregatedPayload;
  }

  public async getElecHalfHourMonthAnalysisDataLoad(timePeriod: TimePeriod) {
    const temporalData = await this.getStellioTemporalData(
      timePeriod,
      'PT30M',
      FluidType.ELECTRICITY
    );

    if (!temporalData) return;

    const getRegroupedAveragesByTimeInterval = (
      _temporalData: StellioGraphAggregated[]
    ) => {
      const intervalMap: { [timeIntervalKey: string]: number[] } = {};
      _temporalData.forEach((entry) => {
        const dateForInterval = entry[2];
        const timeIntervalKey = dateForInterval.split('T')[1];

        if (!intervalMap[timeIntervalKey]) {
          intervalMap[timeIntervalKey] = [];
        }

        const consumption = entry[0];
        intervalMap[timeIntervalKey].push(consumption);
      });

      const averagesByTimeInterval = Object.keys(intervalMap).map(
        (timeIntervalKey) => {
          const consumptions = intervalMap[timeIntervalKey];
          const averageConsumption =
            consumptions.reduce((sum, val) => sum + val, 0) /
            consumptions.length;
          return { timeIntervalKey, averageConsumption };
        }
      );
      return averagesByTimeInterval;
    };

    const checkIsWeekend = (isoDateString: string) =>
      DateTime.fromISO(isoDateString).isWeekend;

    const weekDaysTemporalData = temporalData.filter(
      (entry) => !checkIsWeekend(entry[2])
    );

    const weekendDaysTemporalData = temporalData.filter((entry) =>
      checkIsWeekend(entry[2])
    );

    const getEcolyoStandard = ({
      averageConsumption,
      timeIntervalKey,
    }): Dataload => ({
      date: DateTime.fromISO(timeIntervalKey),
      value: averageConsumption,
      state: DataloadState.VALID,
      valueDetail: null,
    });

    const monthDataLoads: AggregatedEnedisMonthlyDataloads = {
      week: getRegroupedAveragesByTimeInterval(weekDaysTemporalData).map(
        getEcolyoStandard
      ),
      weekend: getRegroupedAveragesByTimeInterval(weekendDaysTemporalData).map(
        getEcolyoStandard
      ),
    };

    return monthDataLoads;
  }
}

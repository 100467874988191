import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'assets/icons/ico/close.svg';
import defaultIcon from 'assets/icons/visu/ecogesture/default.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import useExploration from 'hooks/useExploration';
import { useTranslation } from 'react-i18next';
import { Ecogesture } from 'models';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getPicto } from 'utils/picto';
import { importIconById } from 'utils/utils';
import EfficiencyRating from '../EfficiencyRating/EfficiencyRating';
import './ecogestureModal.scss';
import { getEcogestureIconId } from 'utils/ecoGagnant';

interface EcogestureModalProps {
  open: boolean;
  ecogesture: Ecogesture;
  isAction: boolean;
  handleCloseClick: () => void;
  selectEcogesture?: () => void;
}

const EcogestureModal = ({
  open,
  ecogesture,
  isAction,
  handleCloseClick,
  selectEcogesture,
}: EcogestureModalProps) => {
  const { t } = useTranslation();
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const [ecogestureIcon, setEcogestureIcon] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const [, setValidExploration] = useExploration();

  useEffect(() => {
    async function handleEcogestureIcon() {
      const icon = await importIconById(
        getEcogestureIconId(ecogesture),
        'ecogesture'
      );
      setEcogestureIcon(icon || defaultIcon);
      if (currentChallenge?.exploration.ecogesture_id === ecogesture._id) {
        setValidExploration(currentChallenge.exploration.id);
      }
    }
    if (ecogesture) {
      handleEcogestureIcon();
    }
  }, [ecogesture, setValidExploration, currentChallenge]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper no-padding blue-border',
      }}
    >
      <div id="accessibility-title">
        {isAction
          ? t('ecogesture_modal.accessibility.window_title_action')
          : t('ecogesture_modal.accessibility.window_title_ecogesture')}
      </div>
      <IconButton
        aria-label={t('ecogesture_modal.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={handleCloseClick}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <div className="em-header text-14-normal-uppercase">
        {isAction
          ? t('ecogesture_modal.title_action')
          : t('ecogesture_modal.title_ecogesture')}
      </div>

      <div className="em-root ecogesture-modal">
        <div className="em-content">
          <div className="em-content-box-img">
            {ecogestureIcon && (
              <StyledIcon className="icon" icon={ecogestureIcon} width={100} />
            )}
          </div>
          <div className="em-title text-24-bold ">{ecogesture.shortName}</div>
          <div className="em-detail">
            <div className="em-detail-efficiency">
              <span className="em-efficiency">
                {t('ecogesture_modal.efficiency')}
              </span>
              <EfficiencyRating result={Math.round(ecogesture.efficiency)} />
            </div>
            <div className="em-picto-flow">
              {ecogesture.fluidTypes.map((fluid, index) => (
                <div key={index}>
                  <StyledIcon
                    className="em-pic-content"
                    icon={getPicto(fluid)}
                    width={25}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="text-16-bold long-name">{ecogesture.longName}</div>
          {isAction ? (
            <Button
              aria-label={t(
                'ecogesture_modal.accessibility.button_select_action'
              )}
              className="btn-action-launch text-16-bold"
              onClick={selectEcogesture}
            >
              <span>{t('ecogesture_modal.select_action')}</span>
            </Button>
          ) : (
            <>
              <Collapse in={showDetails}>
                <div
                  className="em-description text-16-normal-150"
                  dangerouslySetInnerHTML={{
                    __html: ecogesture.longDescription,
                  }}
                ></div>
              </Collapse>
              <Button
                aria-label={t(
                  'ecogesture_modal.accessibility.button_see_more_detail'
                )}
                onClick={() => setShowDetails((prev) => !prev)}
                className="btn-secondary-negative text-14-normal"
              >
                <span>
                  {t(`ecogesture_modal.show_${showDetails ? 'less' : 'more'}`)}
                </span>
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default EcogestureModal;

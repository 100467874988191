import classNames from 'classnames';
import useExploration from 'hooks/useExploration';
import {
  FluidRetrievalStatus,
  FluidSlugType,
  FluidState,
  UserActionState,
  UserChallengeState,
  UserDuelState,
  UserExplorationID,
  UserExplorationState,
} from 'enums';
import { DateTime } from 'luxon';
import {
  FluidStatus,
  InitSteps,
  InitStepsErrors,
  ProfileType,
  UserChallenge,
} from 'models';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ActionService from 'services/action.service';
import { setAnalysisMonth } from 'store/analysis/analysis.slice';
import {
  setChallengeConsumption,
  setChallengesList,
  setCurrentUserChallenge,
  setCustomQuestionsList,
  setDuelsList,
  setEcogesturesList,
  setExplorationsList,
  setQuestionsList,
  setQuizList,
  setUserChallengeList,
  setUserChallengeProgress,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { setSelectedDate } from 'store/chart/chart.slice';
import {
  setFluidMonitoring,
  setFluidStatus,
  showReleaseNotes,
  toggleAnalysisNotification,
  toggleChallengeActionNotification,
  toggleChallengeDuelNotification,
  toggleChallengeExplorationNotification,
} from 'store/global/global.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getStellioFluidSlug, getTodayDate } from 'utils/utils';
import SplashScreen from './SplashScreen';
import SplashScreenError from './SplashScreenError';
import './splashRoot.scss';
import ChallengeService from 'services/challenge.service';
import AppAxiosService from 'services/appAxios.service';
import {
  FirstSetupEntities,
  InitChallengeConfig,
  InitChallengeEntitiesList,
  StellioChallengeProgress,
  StellioEcogesture,
  StellioEntityType,
  StellioExploration,
  StellioFluidMonitoring,
  StellioFluidTypeSlug,
  StellioUserProfile,
} from 'models/stellio.model';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  spreadStellioUserProfile,
  updateStellioUserProfile,
} from 'store/profileStellioUser/profileStellioUser.slice';
import useBackendPopups from 'hooks/useBackendPopups';
import { appLogger } from 'utils/appLogger';

interface SplashRootProps {
  fadeTimer?: number;
  children: ReactNode;
}

/**
 * Added splash screen if data is not ready
 * @param params {{ fadeTimer, splashComponent, children }}
 */
const SplashRoot = ({ fadeTimer = 1000, children }: SplashRootProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const profileStellioUser = useAppSelector(
    (store) => store.profileStellioUser
  );
  const initialFluidStatus = useAppSelector(
    (store) => store.global.fluidStatus
  );
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const [{ splashEnd, splashStart }, setState] = useState({
    splashEnd: false,
    splashStart: false,
  });
  const isStellioProfileHandled = useRef(false);
  const isSplashSetupHandled = useRef(false);
  const [initStep, setInitStep] = useState<InitSteps>(InitSteps.MIGRATION);
  const [initStepErrors, setInitStepErrors] = useState<InitStepsErrors | null>(
    null
  );
  useBackendPopups();

  const handleFluidStatus = (
    userSub: string,
    firstSetupEntities: FirstSetupEntities[]
  ) => {
    const fluidMonitoringList = firstSetupEntities.filter(
      (entity) => entity.type === StellioEntityType.FLUID_MONITORING
    ) as StellioFluidMonitoring[];

    const fluidStatus = initialFluidStatus.map((fluid): FluidStatus => {
      const getFluidStatus = (fluidMonitoring: StellioFluidMonitoring) => {
        const retrievalStatus = fluidMonitoring.retrievalStatus.value;
        const lastDataDate =
          retrievalStatus !== FluidRetrievalStatus.NOT_STARTED
            ? DateTime.local().minus({ day: 1 }).endOf('day')
            : null;
        let updatedFluid: FluidStatus = {
          ...fluid,
          lastDataDate,
          retrievalStatus,
          status: fluidMonitoring.isConnected.value
            ? FluidState.DONE
            : FluidState.NOT_CONNECTED,
        };

        return updatedFluid;
      };

      const findFluidMonitoring = (
        fluidTypeSlug: StellioFluidTypeSlug,
        fluidMonitoringList: StellioFluidMonitoring[]
      ): StellioFluidMonitoring | undefined => {
        return fluidMonitoringList.find(
          (fluidMonitoring) =>
            fluidMonitoring.id.includes(userSub) &&
            fluidMonitoring.fluidType.value === fluidTypeSlug
        );
      };

      let fluidTypeSlug = getStellioFluidSlug(fluid.fluidType);

      const found = findFluidMonitoring(fluidTypeSlug, fluidMonitoringList);
      if (found) {
        dispatch(setFluidMonitoring(found));
        return getFluidStatus(found);
      }

      return fluid;
    });

    dispatch(setFluidStatus(fluidStatus));

    const refDate = DateTime.fromISO('0001-01-01');
    let lastDataDate: DateTime | null = DateTime.fromISO('0001-01-01');
    for (const fluid of fluidStatus) {
      if (fluid.lastDataDate && fluid.lastDataDate > lastDataDate) {
        lastDataDate = fluid.lastDataDate;
      }
    }
    if (lastDataDate > refDate) {
      dispatch(setSelectedDate(lastDataDate));
    }
  };

  useEffect(() => {
    let timeoutSplash: NodeJS.Timeout;
    if (splashStart) {
      timeoutSplash = setTimeout(() => {
        setState((prev) => ({ ...prev, splashEnd: true }));
      }, fadeTimer);
    }
    return () => timeoutSplash && clearTimeout(timeoutSplash);
  }, [splashStart, fadeTimer]);

  const [, setValidExploration] = useExploration();

  useEffect(() => {
    let subscribed = true;
    async function loadData() {
      if (!userSub) return console.warn('User sub not set');
      console.group('Splash root loadData logs');
      // const initializationService = new InitializationService(
      //   client,
      //   setInitStep,
      //   setInitStepErrors
      // );

      // const ms = new MigrationService(client, setInitStepErrors);
      // const startTime = performance.now();
      try {
        // init Terms
        // const termsStatus = await initializationService.initConsent();
        // if (subscribed) dispatch(updateTermsStatus(termsStatus));

        // Init fluidPrices
        // await initializationService.initFluidPrices();

        // Init profile and update ecogestures, challenges, analysis
        // const profile = await initializationService.initProfile();
        // const profileType = await initializationService.initProfileType();
        // const profileEcogesture =
        //   await initializationService.initProfileEcogesture();

        // const migrationsResult = await ms.runMigrations(migrations);
        // Init last release notes when they exist
        // dispatch(
        //   showReleaseNotes({
        //     notes: migrationsResult.notes,
        //     redirectLink: migrationsResult.redirectLink,
        //     show: migrationsResult.show,
        //   })
        // );
        // if (subscribed /** && profile */) {
        setValidExploration(UserExplorationID.EXPLORATION007);
        // const [
        // duelHash,
        // quizHash,
        // challengeHash,
        // explorationHash,
        // analysisResult,
        // ] = await Promise.all([
        // initializationService.initDuelEntity(profile.duelHash),
        // initializationService.initQuizEntity(profile.quizHash),
        // initializationService.initExplorationEntity(profile.challengeHash),
        // initializationService.initChallengeEntity(profile.explorationHash),
        // initializationService.initAnalysis(profile),
        // ]);
        // const updatedProfile: Partial<Profile> = {
        // duelHash,
        // quizHash,
        // challengeHash,
        // explorationHash,
        // monthlyAnalysisDate: analysisResult.monthlyAnalysisDate,
        // haveSeenLastAnalysis: analysisResult.haveSeenLastAnalysis,
        // };
        // dispatch(updateProfile(updatedProfile));
        // dispatch(setAnalysisMonth(analysisResult.monthlyAnalysisDate));
        // if (profileType) {
        //   await loadProfileType(profileType);
        // }
        // if (profileEcogesture) {
        //   dispatch(setProfileEcogesture(profileEcogesture));
        // }
        // dispatch(toggleAnalysisNotification(!profile.haveSeenLastAnalysis));
        // }
        // Init Fluid status && lastDate for the chart
        // const fluidStatus = await initializationService.initFluidStatus();
        // if (subscribed) {
        setInitStep(InitSteps.CONSENT);

        const stellioAxios = new AppAxiosService();

        const firstSetupEntities =
          await stellioAxios.getEntitiesByTypes<FirstSetupEntities>([
            StellioEntityType.FLUID_MONITORING,
            StellioEntityType.CHALLENGE_PROGRESS,
          ]);

        handleFluidStatus(userSub, firstSetupEntities);

        const challengeProgressList = firstSetupEntities.filter(
          (entity) => entity.type === StellioEntityType.CHALLENGE_PROGRESS
        ) as StellioChallengeProgress[];
        const challengeProgress = challengeProgressList.find(
          (challengeProgress) => challengeProgress.id.includes(userSub)
        );

        if (challengeProgress) {
          dispatch(setUserChallengeProgress(challengeProgress));
        }

        setInitStep(InitSteps.CHALLENGES);

        console.group('GET initChallengeEntities');
        const initChallengeEntities =
          await stellioAxios.getEntitiesByTypes<InitChallengeEntitiesList>([
            StellioEntityType.CHALLENGE,
            StellioEntityType.DUEL,
            StellioEntityType.EXPLORATION,
            StellioEntityType.QUESTION,
            StellioEntityType.CUSTOM_QUESTION,
            StellioEntityType.QUIZ,
            StellioEntityType.USER_PROFILE,
            StellioEntityType.ECOGESTURE,
          ]);

        appLogger(
          `${new Date().toISOString()} - initChallengeEntities:`,
          initChallengeEntities
        );

        const initChallengeConfig: InitChallengeConfig[] = [
          {
            entityType: StellioEntityType.CHALLENGE,
            setDataAction: setChallengesList,
            data: [],
          },
          {
            entityType: StellioEntityType.DUEL,
            setDataAction: setDuelsList,
            data: [],
          },
          {
            entityType: StellioEntityType.EXPLORATION,
            setDataAction: setExplorationsList,
            data: [],
          },
          {
            entityType: StellioEntityType.QUESTION,
            setDataAction: setQuestionsList,
            data: [],
          },
          {
            entityType: StellioEntityType.CUSTOM_QUESTION,
            setDataAction: setCustomQuestionsList,
            data: [],
          },
          {
            entityType: StellioEntityType.QUIZ,
            setDataAction: setQuizList,
            data: [],
          },
          {
            entityType: StellioEntityType.ECOGESTURE,
            setDataAction: setEcogesturesList,
            data: [],
          },
        ];

        for (const confItem of initChallengeConfig) {
          const data = initChallengeEntities.filter(
            (entity) => entity.type === confItem.entityType
          );
          dispatch(confItem.setDataAction(data));
          confItem.data = data;
        }

        console.groupEnd();

        setInitStep(InitSteps.PRICES);

        const ecogestures = initChallengeConfig.find(
          (conf) => conf.entityType === StellioEntityType.ECOGESTURE
        )?.data as StellioEcogesture[];
        const explorations = initChallengeConfig.find(
          (conf) => conf.entityType === StellioEntityType.EXPLORATION
        )?.data as StellioExploration[];

        if (!ecogestures || !explorations) {
          console.warn(
            'Some data were not set properly (ecogestures, explorations)'
          );
        }

        // Check progress for action, exploration, duel
        if (subscribed && challengeProgress) {
          const challengeService = new ChallengeService(
            stellioAxios,
            userSub,
            challengeProgress,
            ecogestures,
            explorations
          );

          const { currentUserChallenge, userChallengeList } =
            challengeService.getParsedAndTransformedUserChallenges();

          if (!userChallengeList) return console.warn('no user challenge list');

          dispatch(setUserChallengeList(userChallengeList));
          dispatch(setCurrentUserChallenge(currentUserChallenge));

          // Set Notification if exploration state is notification
          if (
            currentUserChallenge?.exploration.state ===
            UserExplorationState.NOTIFICATION
          ) {
            dispatch(toggleChallengeExplorationNotification(true));
          }

          // Set action to notification if action is accomplished
          if (currentUserChallenge?.action.state === UserActionState.ONGOING) {
            const actionService = new ActionService(
              stellioAxios,
              userSub,
              challengeProgress,
              ecogestures,
              explorations
            );

            const updatedUserChallenge: UserChallenge | null =
              await actionService.isActionDone(currentUserChallenge);
            if (updatedUserChallenge) {
              dispatch(updateUserChallengeList(updatedUserChallenge));
            }
          }

          // Set Notification if action state is notification
          if (
            currentUserChallenge?.action.state === UserActionState.NOTIFICATION
          ) {
            dispatch(toggleChallengeActionNotification(true));
          }

          const filteredCurrentDuelChallenge = userChallengeList.filter(
            (challenge) => challenge.state === UserChallengeState.DUEL
          );

          if (
            currentUserChallenge &&
            filteredCurrentDuelChallenge[0]?.duel.state ===
              UserDuelState.ONGOING
          ) {
            const { updatedUserChallenge, dataloads } =
              await challengeService.initChallengeDuelProgress(
                currentUserChallenge
              );

            if (subscribed) {
              dispatch(
                setChallengeConsumption({
                  userChallenge: updatedUserChallenge,
                  currentDataload: dataloads,
                })
              );
              // Check is duel is done and display notification
              const { isDone } = await challengeService.isChallengeDone(
                updatedUserChallenge,
                dataloads
              );
              dispatch(toggleChallengeDuelNotification(isDone));
            }
          }
        }

        if (subscribed) {
          // logDuration("[Initialization] Finished successfully !", startTime);
          setInitStep(InitSteps.CONSOS);
          setState((prev) => ({
            ...prev,
            splashStart: true,
          }));
        }

        isSplashSetupHandled.current = true;
      } catch (error: any) {
        setInitStepErrors(InitStepsErrors.UNKNOWN_ERROR);
        console.error(error);

        // if (error.message === 'Failed to fetch' && !initStepErrors) {
        //   setInitStepErrors(InitStepsErrors.UNKNOWN_ERROR);
        // }
        // logApp.error(`[Initialization] Error : ${error}`);
      } finally {
        // transaction.finish();
        console.groupEnd();
      }
    }

    if (isSplashSetupHandled.current) return;

    if (!initStepErrors) loadData();
    return () => {
      subscribed = false;
    };
  }, [dispatch, initStepErrors, userSub, setValidExploration]);

  useEffect(() => {
    if (!userSub) return;

    const handleUserProfile = async () => {
      const stellioAxios = new AppAxiosService();

      const { data: userProfiles } =
        await stellioAxios.getEntityByType<StellioUserProfile>(
          StellioEntityType.USER_PROFILE
        );

      const userProfile = userProfiles.find((userProfile) =>
        userProfile.id.includes(userSub)
      );

      if (!userProfile) {
        // First connection: create UserProfile entity
        navigate('/profiletype');
      } else {
        // Set user profiles
        dispatch(spreadStellioUserProfile(userProfile));
        const todayISO = getTodayDate().toISO();
        if (todayISO) {
          dispatch(
            updateStellioUserProfile({
              lastConnectionDate: {
                type: 'Property',
                value: todayISO,
                previous: {
                  type: 'Property',
                  value: userProfile.lastConnectionDate.value,
                },
              },
              accountInactivityFirstAlert: {
                type: 'Property',
                value: false,
              },
              accountInactivitySecondAlert: {
                type: 'Property',
                value: false,
              },
            })
          );
        }
      }
      isStellioProfileHandled.current = true;
    };

    handleUserProfile();
  }, [userSub]);

  // Force redirection until profile type is completed
  useEffect(() => {
    if (!isStellioProfileHandled.current) return;

    const allowedPages = [
      '/profilType',
      '/options',
      '/options/legalnotice',
      '/options/gcu',
    ];
    if (!allowedPages.includes(pathname) && !profileStellioUser.id) {
      navigate('/profiletype');
    }
  }, [pathname, userSub, profileStellioUser.id, navigate]);

  return (
    <>
      {!splashEnd && (
        <div
          style={{ transitionDuration: `${fadeTimer / 1000}s` }}
          className={classNames('splash-root', {
            ['splash-fade']: splashStart,
          })}
        >
          {!initStepErrors ? (
            <SplashScreen initStep={initStep} />
          ) : (
            <SplashScreenError error={initStepErrors} />
          )}
        </div>
      )}

      {splashStart && children}
    </>
  );
};
export default SplashRoot;
